import * as React from "react"
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../styles/main.scss"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Helmet>
      <title>Story Wars</title>
    </Helmet>
    <div className="layout">
      <StaticImage 
        src="../images/header-logo.png"
        alt="Story Wars"
        className="image"
        width={600}
        placeholder="blurred"
        loading="eager"
        />
      <h1>Thank you for your support</h1>
      <p>We are sorry to announce that Story Wars has closed for the forseeable future.</p>
      <p className="standout">We’d like to say a big thank you to everyone that has contributed over the years and helped to create some truly amazing stories and grow a community of writers.</p>
      <p className="standout">Please keep on writing.</p>
      <p>If you have any questions regarding Story Wars please contact us: <a href="mailto:contact@storywars.net">contact@storywars.net</a></p>
    </div>
  </Layout>
)

export default IndexPage
